/**
 * Little Shilling, Inc.
 * Shon Little
 * June 20, 2020
 */

/**
 * Create Material-UI global light theme.
 * @param {Object} - Theme options.
 * @returns {Object} Theme.
 */
const themeReleve = {
  palette: {
    primary: {
      main: '#e0e0e0',
      contrastText: '#616161',
      purple: '#4F0B7B',
    },
    secondary: {
      main: '#757575',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    fontSize: 12,
    h1: {
      fontWeight: 400,
      fontSize: '3rem',
      fontFamily: ['Dancing Script', 'cursive', 'sans-serif'].join(','),
    },
    h2: {
      fontWeight: 400,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.75rem',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    body1: {
      fontSize: '0.75rem',
    },
    signature: {
      fontWeight: 600,
      fontSize: '1.5rem',
      fontFamily: ['Dancing Script', 'cursive', 'sans-serif'].join(','),
    },
  },
  components: {
    // Style rules for MUI Link component or HTML <a> element
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none', // Removes the underline
          '&:hover': {
            textDecoration: 'underline', // Adds underline on hover
          },
        },
      },
    },
  },
};

// Export theme
export default themeReleve;
