/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-08-14
 */

// Add third-party dependencies.
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DataGrid, GridActionsCellItem, GridRowModes, GridRowEditStopReasons } from '@mui/x-data-grid';
import {
  Save as SaveIcon,
  Delete as DeleteIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  EditOutlined as EditOutlinedIcon,
  Replay as ReplayIcon,
} from '@mui/icons-material';
import { Box, TextField, Tooltip, Paper, Typography } from '@mui/material';

// Add local dependencies.
import { parseDateAsLocal, calculateHours } from '../../../assets/helpers/timecalc';
import { formatTime } from '../../../assets/helpers/format';
import { useUpdateActivityMutation, useDeleteActivityMutation } from '../../../api/employSlice';
import Question from '../../Common/Question';
import ErrorAlert from '../../Common/ErrorAlert';
import PageSpinner from '../../Common/PageSpinner';

/**
 * TimesheetGrid component.
 * @example
 * return (
 *   <TimesheetGrid />
 * )
 * @returns {React.ReactElement} component.
 */
const TimesheetGrid = ({ activities, payRates, onReplay }) => {
  // Set state hooks.
  const [rows, setRows] = useState(activities);
  const [rowModesModel, setRowModesModel] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);

  // RTK Query hooks for updating and deleting activities
  const [updateActivity, { isLoading: isUpdating }] = useUpdateActivityMutation();
  const [deleteActivity, { isLoading: isDeleting }] = useDeleteActivityMutation();

  // Filter activities based on the showProcessed state
  useEffect(() => {
    if (activities) setRows(activities);
  }, [activities]);

  // Handle row edit stop event.
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true; // eslint-disable-line no-param-reassign
    }
  };

  // Handle replay click event.
  const handleReplayClick = (id) => () => {
    const rowData = rows.find((row) => row.id === id);
    if (rowData) onReplay(rowData);
  };

  // Handle edit click event.
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  // Handle save click event.
  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // Handle delete click event.
  const handleDeleteClick = (id) => () => {
    setRowToDelete(id);
    setDeleteDialogOpen(true);
  };

  // Handle confirm delete event.
  const handleConfirmDelete = async () => {
    if (rowToDelete) {
      try {
        await deleteActivity(rowToDelete).unwrap();
        setRows((prevRows) => prevRows.filter((row) => row.id !== rowToDelete));
      } catch (error) {
        console.error('Failed to delete row:', error);
        setErrorAlert(error);
      } finally {
        setDeleteDialogOpen(false);
        setRowToDelete(null);
      }
    }
  };

  // Handle cancel delete event.
  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setRowToDelete(null);
  };

  // Handle cancel click event.
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  // Process row update.
  const processRowUpdate = async (updatedRow) => {
    try {
      // Validation logic.
      if (!updatedRow.activity) throw new Error('Activity cannot be empty.');
      if (updatedRow.start_time >= updatedRow.end_time) throw new Error('Start time must be earlier than end time.');
      if (updatedRow.headcount < 0) throw new Error('Headcount cannot be a negative.');
      // Ensure the date is in the correct format (YYYY-MM-DD).
      const formattedRow = {
        ...updatedRow,
        date: updatedRow.date.toISOString().split('T')[0], // Extract the date portion.
      };
      await updateActivity({ id: formattedRow.id, ...formattedRow }).unwrap();
      // Update the local state with the new data.
      setRows((prevRows) => prevRows.map((row) => (row.id === updatedRow.id ? formattedRow : row)));
      return formattedRow;
    } catch (error) {
      console.error('Failed to update row:', error);
      setErrorAlert(error);
      throw error; // Revert the changes in the grid if the update fails.
    }
  };

  // Handle row modes model change.
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Calculate the pay for each row.
  const calculatePay = (row) => {
    if (!row.pay_rate) return 0;
    const payRate = payRates.find((r) => r.id === row.pay_rate);
    const hours = calculateHours(row.start_time, row.end_time);
    const hourlyRate = payRate.flat_rate ? payRate.rate : payRate.rate * row.headcount;
    const hourlyPay = hours * hourlyRate;
    const minimumPay = Number(payRate.minimum || 0); // Handle cases where minimum is not set
    const pay = hourlyPay < minimumPay ? minimumPay : hourlyPay;
    return pay;
  };

  // Calculate the sum of pay where processed is false.
  const totalUnprocessedPay = useMemo(() => {
    return rows
      .filter((row) => !row.processed)
      .reduce((sum, row) => sum + calculatePay(row), 0)
      .toFixed(2);
  }, [rows]);

  // Create single select value options.
  const singleSelectValueOptions = payRates.map((rate) => ({
    value: rate.id,
    label: `${rate.rate_name}: $${rate.rate}/${rate.flat_rate ? 'hour' : 'head'}`,
  }));

  // Set column definitions.
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (row.processed) {
          // If the row is processed, disable the Edit and Delete buttons
          return [
            <Tooltip title="Repeat row in form" key="replay-disabled">
              <GridActionsCellItem icon={<ReplayIcon />} label="Replay" onClick={handleReplayClick(id)} />
            </Tooltip>,
            <Tooltip title="Cannot edit a processed row" key="edit-disabled">
              <GridActionsCellItem icon={<EditOutlinedIcon sx={{ color: 'gray' }} />} label="Edit" />
            </Tooltip>,
            <Tooltip title="Cannot delete a processed row" key="delete-disabled">
              <GridActionsCellItem icon={<DeleteOutlinedIcon sx={{ color: 'gray' }} />} label="Delete" />
            </Tooltip>,
          ];
        }

        if (isInEditMode) {
          return [
            <Tooltip title="Save changes" key="save">
              <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} />
            </Tooltip>,
            <Tooltip title="Cancel changes" key="cancel">
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
              />
            </Tooltip>,
          ];
        }

        return [
          <Tooltip title="Repeat row in form" key="replay">
            <GridActionsCellItem icon={<ReplayIcon />} label="Replay" onClick={handleReplayClick(id)} />
          </Tooltip>,
          <Tooltip title="Edit row" key="edit">
            <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(id)} />
          </Tooltip>,
          <Tooltip title="Delete row" key="delete">
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} />
          </Tooltip>,
        ];
      },
    },
    { field: 'id', headerName: 'ID' },
    { field: 'activity', headerName: 'Activity', editable: true },
    {
      field: 'date',
      headerName: 'Date',
      editable: true,
      type: 'date',
      valueGetter: (date) => (typeof date === 'string' ? parseDateAsLocal(date) : date),
    },
    {
      field: 'start_time',
      headerName: 'Start Time',
      editable: true,
      valueFormatter: (value) => formatTime(value),
      renderEditCell: (params) => (
        <TextField
          type="time"
          value={params.value || ''}
          onChange={(event) => {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
          }}
          variant="standard"
          InputProps={{ style: { padding: '5px' } }}
        />
      ),
    },
    {
      field: 'end_time',
      headerName: 'End Time',
      editable: true,
      valueFormatter: (value) => formatTime(value),
      renderEditCell: (params) => (
        <TextField
          type="time"
          value={params.value || ''}
          onChange={(event) => {
            params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
          }}
          variant="standard"
          InputProps={{ style: { padding: '5px' } }}
        />
      ),
    },
    { field: 'headcount', headerName: 'Headcount', editable: true, type: 'number' },
    {
      field: 'pay_rate',
      headerName: 'Pay Rate',
      editable: true,
      type: 'singleSelect',
      width: 200,
      valueOptions: singleSelectValueOptions,
    },
    {
      field: 'pay',
      headerName: 'Pay',
      type: 'number',
      valueGetter: (params, row) => calculatePay(row),
      valueFormatter: (params) => `$${params.toFixed(2)}`,
    },
    { field: 'processed', headerName: 'Processed', type: 'boolean' },
  ];

  // Render component.
  return (
    <Box>
      {errorAlert && (
        <ErrorAlert error={errorAlert} fallback="An unexpected error occurred. Please try again later." />
      )}
      {(isUpdating || isDeleting) && <PageSpinner />}
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        density="compact"
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        getRowClassName={(params) => (params.row.processed ? 'processed-row' : '')}
        sx={{ '& .processed-row': { backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'secondary.main' } }}
        pageSizeOptions={[25, 50, 100, 200, 500]}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          sorting: { sortModel: [{ field: 'id', sort: 'desc' }] },
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
      <Paper sx={{ mt: 1, p: 1 }}>
        <Typography variant="h6" component="h2" align="center">
          Total Unprocessed Pay: <strong>${totalUnprocessedPay}</strong>
        </Typography>
      </Paper>
      <Question
        title="Are you sure?"
        option1Handler={handleConfirmDelete}
        option2Handler={handleCancelDelete}
        question={`Are you sure you want to delete activity (ID ${rowToDelete})?`}
        open={deleteDialogOpen}
        onClose={handleCancelDelete}
      />
    </Box>
  );
};

// Set component property types.
TimesheetGrid.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      user: PropTypes.number.isRequired,
      processed: PropTypes.bool.isRequired,
      activity: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      start_time: PropTypes.string.isRequired,
      end_time: PropTypes.string.isRequired,
      headcount: PropTypes.number.isRequired,
      pay_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    })
  ).isRequired,
  payRates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      rate: PropTypes.string.isRequired,
    })
  ).isRequired,
  onReplay: PropTypes.func.isRequired,
};

// Export component.
export default TimesheetGrid;
