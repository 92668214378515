/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-09-23
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, Unstable_Grid2 as Grid, Button } from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import FormText from '../../Common/FormText';
import { useGetDeskPayMutation } from '../../../api/payrollSlice';
import { getTodaysDate } from '../../../assets/helpers/timecalc';
import PageSpinner from '../../Common/PageSpinner';
import ErrorAlert from '../../Common/ErrorAlert';

/**
 * DeskPay component.
 * @example
 * return (
 *   <DeskPay />
 * )
 * @returns {React.ReactElement} component.
 */
const DeskPay = () => {
  // Set state hooks.
  const [defaultFromDate, setDefaultFromDate] = useState('');
  const [defaultToDate, setDefaultToDate] = useState('');

  // Set mutation hooks.
  const [getDeskPay, { isLoading: getDeskPayIsLoading, data: getDeskPayData }] = useGetDeskPayMutation();

  // Set form hooks.
  const { control, handleSubmit, setValue } = useForm();

  // Set effect hook.
  useEffect(() => {
    const today = getTodaysDate();
    const storedFromDate = localStorage.getItem('deskPayFromDate');
    const storedToDate = localStorage.getItem('deskPayToDate');

    const fromDate = storedFromDate || today;
    const toDate = storedToDate || today;

    setDefaultFromDate(fromDate);
    setDefaultToDate(toDate);

    setValue('deskPayFromDate', fromDate); // Set the default value for the from date field
    setValue('deskPayToDate', toDate); // Set the default value for the to date field
  }, [setValue]);

  /**
   * Handle form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    localStorage.setItem('deskPayFromDate', data.deskPayFromDate); // Save the from date to local storage
    localStorage.setItem('deskPayToDate', data.deskPayToDate); // Save the to date to local storage
    await getDeskPay({
      start_date: data.deskPayFromDate,
      end_date: data.deskPayToDate,
    });
  };

  if (defaultFromDate === undefined || defaultToDate === undefined) return null;

  // Render component.
  return (
    <AuthMenuGroup>
      {getDeskPayIsLoading && <PageSpinner />}
      <Box>
        <Typography variant="h1" gutterBottom>
          Front Desk Pay
        </Typography>
      </Box>
      {getDeskPayData?.error && <ErrorAlert error={getDeskPayData?.error} fallback="Unknown error occurred." />}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ width: '100%' }}>
        <Grid container spacing={1}>
          <Grid xs={5}>
            <FormText
              control={control}
              name="deskPayFromDate"
              label="From Date"
              type="date"
              defaultValue={defaultFromDate}
              rules={{ required: 'From date is required.' }}
              margin="dense"
              shrinkLabel
            />
          </Grid>
          <Grid xs={5}>
            <FormText
              control={control}
              name="deskPayToDate"
              label="To Date"
              type="date"
              defaultValue={defaultToDate}
              rules={{ required: 'To date is required.' }}
              margin="dense"
              shrinkLabel
            />
          </Grid>

          <Grid xs={2}>
            <Button type="submit" fullWidth variant="contained" sx={{ marginTop: 2 }}>
              Get Total
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2}>
        <Typography variant="h2">
          {getDeskPayData?.desk_pay ? `Front Desk Pay: $${getDeskPayData?.desk_pay}` : ''}
        </Typography>
      </Box>
    </AuthMenuGroup>
  );
};

// Export component.
export default DeskPay;
