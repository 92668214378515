/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-25
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Typography, Button } from '@mui/material';

// Add local dependencies.
import { useCreateUserMutation, useLoginUserMutation } from '../../api/authSlice';
import ErrorAlert from '../Common/ErrorAlert';
import PageSpinner from '../Common/PageSpinner';
import FormText from '../Common/FormText';

/**
 * CreateAccount component.
 * @example
 * return (
 *   <CreateAccount />
 * )
 * @returns {React.ReactElement} component.
 */
const CreateAccount = () => {
  // Set state hook.
  const [errorMessage, setErrorMessage] = useState(null);

  // Set navigate hook.
  const navigate = useNavigate();

  // Initialize react-hook-form.
  const { control, handleSubmit, getValues } = useForm();

  // Set mutation hooks.
  const [createUser, { isLoading: isCreatingUser, error: createUserError }] = useCreateUserMutation();
  const [loginUser, { isLoading: isLoggingIn, error: loginError }] = useLoginUserMutation();

  // Handle error state.
  useEffect(() => {
    if (createUserError) {
      console.error('Error', createUserError);
      setErrorMessage(createUserError?.data?.username[0] || 'Failed to create user. Please try again.');
    }
  }, [createUserError]);

  useEffect(() => {
    if (loginError) {
      console.error('Login Error', loginError);
      setErrorMessage('Failed to log in after registration. Please try logging in manually.');
    }
  }, [loginError]);

  // Render loading indicator while checking the auth state.
  if (isCreatingUser || isLoggingIn) return <PageSpinner />;

  /**
   * Handle form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    try {
      if (data.auth_word !== 'corbin') {
        setErrorMessage('Invalid authorization word.');
        return;
      }
      const createUserResponse = await createUser({
        email: data.email,
        username: data.username,
        password: data.password,
        re_password: data.re_password,
      }).unwrap();

      if (createUserResponse) {
        setErrorMessage(null);
        try {
          const loginResponse = await loginUser({
            username: data.username,
            password: data.password,
          }).unwrap();

          if (loginResponse) {
            navigate('/welcome');
          }
        } catch {
          navigate('/login');
        }
      }
    } catch (err) {
      setErrorMessage('Failed to create user. Please try again.');
      console.error('Error', err);
    }
  };

  // Password validation rules.
  const passwordValidationRules = {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters long',
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      message: 'Password must contain at least one uppercase letter, one lowercase letter, and one number',
    },
  };

  /**
   * Handle key press events.
   * @param {Event} event - Key press event.
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  // Render component.
  return (
    <Box p={3}>
      <Typography variant="h1">Create Account</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1, maxWidth: 400 }}>
        {errorMessage && <ErrorAlert error={errorMessage} fallback="An error occurred during registration." />}
        <FormText
          control={control}
          name="auth_word"
          label="Authorization Word"
          type="password"
          rules={{
            required: 'Authorization word is required.',
          }}
          autoFocus
        />
        <FormText
          control={control}
          name="email"
          label="Email"
          type="email"
          rules={{
            required: 'Email is required.',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'Invalid email address.',
            },
          }}
        />
        <FormText
          control={control}
          name="username"
          label="Username"
          rules={{
            required: 'Username is required.',
            minLength: {
              value: 1,
              message: 'Username must be at least 1 character long.',
            },
            maxLength: {
              value: 150,
              message: 'Username cannot exceed 150 characters.',
            },
            pattern: {
              value: /^[\w.@+-]+$/,
              message: 'Invalid username. Letters, digits and @/./+/-/_ only.',
            },
          }}
        />
        <FormText control={control} name="password" label="Password" type="password" rules={passwordValidationRules} />
        <FormText
          control={control}
          name="re_password"
          label="Re-enter Password"
          type="password"
          onKeyDown={handleKeyDown}
          rules={{
            required: 'Re-enter Password is required.',
            validate: (value) => value === getValues('password') || 'Passwords do not match.',
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Register
        </Button>
      </Box>
    </Box>
  );
};

// Export component.
export default CreateAccount;
