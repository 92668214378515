/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-25
 */

// Add third-party dependencies.
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography, Alert, Button } from '@mui/material';

// Add local dependencies.
import AuthMenuGroup from '../AuthMenuGroup';
import ErrorAlert from '../../Common/ErrorAlert';
import PageSpinner from '../../Common/PageSpinner';
import { useSetPasswordMutation } from '../../../api/authSlice';
import FormText from '../../Common/FormText';

/**
 * ChangePassword component.
 * @example
 * return (
 *   <ChangePassword />
 * )
 * @returns {React.ReactElement} component.
 */
const ChangePassword = () => {
  // Set state hooks.
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // Set mutation hooks.
  const [setPassword, { isLoading, error }] = useSetPasswordMutation();

  // Initialize react-hook-form.
  const { handleSubmit, control, getValues } = useForm();

  // Handle error state.
  useEffect(() => {
    if (error) {
      console.error('Error', error);
      setErrorMessage(error?.data?.current_password[0] || 'Failed to set new password. Please try again.');
      setSuccessMessage(null);
    }
  }, [error]);

  // Render loading indicator while checking the auth state.
  if (isLoading) return <PageSpinner />;

  /**
   * Handle password form submission.
   * @param {Object} data - Form data.
   * @returns {void}
   */
  const onSubmit = async (data) => {
    try {
      await setPassword({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        reNewPassword: data.reNewPassword,
      });
      setSuccessMessage('Password successfully updated.');
      setErrorMessage(null);
    } catch (err) {
      console.error('Error', err);
      setErrorMessage('Failed to set new password. Please try again.');
      setSuccessMessage(null);
    }
  };

  // Password validation rules.
  const passwordValidationRules = {
    required: 'New Password is required',
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters long',
    },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      message: 'Password must contain at least one uppercase letter, one lowercase letter, and one number',
    },
  };

  /**
   * Handle key press events.
   * @param {Event} event - Key press event.
   * @returns {void}
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  // Render component.
  return (
    <AuthMenuGroup>
      <Typography variant="h1">Change Password</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3, maxWidth: 400 }}>
        {successMessage && <Alert severity="success">{successMessage}</Alert>}
        {errorMessage && <ErrorAlert error={errorMessage} fallback="An error occurred. Please try again." />}
        <FormText
          control={control}
          name="currentPassword"
          label="Current Password"
          type="password"
          autoFocus
          rules={{ required: 'Current Password is required' }}
        />
        <FormText
          control={control}
          name="newPassword"
          label="New Password"
          type="password"
          rules={passwordValidationRules}
        />
        <FormText
          control={control}
          name="reNewPassword"
          label="Re-enter New Password"
          type="password"
          onKeyDown={handleKeyDown}
          rules={{
            required: 'Re-enter Password is required',
            validate: (value) => value === getValues('newPassword') || 'Passwords do not match',
          }}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Change Password
        </Button>
      </Box>
    </AuthMenuGroup>
  );
};

// Export component.
export default ChangePassword;
