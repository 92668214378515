/**
 * © 2024 Little Shilling, Inc.
 * Shon Little
 * Created: 2024-07-25
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl, TextField } from '@mui/material';

/**
 * FormText component.
 * @example
 * return (
 *   <FormText />
 * )
 * @returns {React.ReactElement} component.
 */
const FormText = ({
  control,
  name,
  label,
  defaultValue,
  rules,
  required,
  type,
  autoFocus,
  onKeyDown,
  margin,
  shrinkLabel,
}) => {
  // Render component.
  return (
    <FormControl fullWidth margin={margin}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field, fieldState }) => (
          <TextField
            {...field} // eslint-disable-line react/jsx-props-no-spreading
            name={name}
            id={name}
            autoComplete={name}
            label={label}
            required={required}
            fullWidth
            type={type}
            autoFocus={autoFocus}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            onKeyDown={onKeyDown}
            InputLabelProps={shrinkLabel ? { shrink: true } : {}}
          />
        )}
      />
    </FormControl>
  );
};

// Set component property types.
FormText.propTypes = {
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  rules: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  required: PropTypes.bool,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
  margin: PropTypes.string,
  shrinkLabel: PropTypes.bool,
};

// Set component default properties.
FormText.defaultProps = {
  label: '',
  defaultValue: '',
  rules: { required: `Field is required.` },
  required: true,
  type: 'input',
  autoFocus: false,
  onKeyDown: null,
  margin: 'normal',
  shrinkLabel: false,
};

// Export component.
export default FormText;
